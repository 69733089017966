import { SxProps, Theme } from '@mui/material';

import { ContentfulBackground, Maybe, THeroContentWidth } from '@/types';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';

import { resolveContentPositioning } from './HeroContent.helpers';

export const getWrapperStyles = ({
	isSmallScreen,
	contentPosition,
	backgroundColorSmallScreen,
	theme,
	mediaAspectRatioSmallScreen,
	isNoContent,
}: {
	isSmallScreen: boolean;
	contentPosition?: Maybe<string>;
	backgroundColorSmallScreen?: Maybe<string>;
	theme: Theme;
	mediaAspectRatioSmallScreen?: Maybe<string>;
	isNoContent?: boolean;
}): SxProps => {
	const getWrapperPositionAndBackground = (isSmallScreen: boolean, mediaAspectRatioSmallScreen?: Maybe<string>) => {
		if (isSmallScreen) {
			if (mediaAspectRatioSmallScreen === 'Portrait (9:16)') {
				return { position: 'absolute', background: 'none' };
			}
			return {
				position: 'relative',
				background: resolveContentfulBgColor(backgroundColorSmallScreen as ContentfulBackground, theme),
			};
		}
		return { position: 'relative', background: 'none' };
	};

	return {
		...getWrapperPositionAndBackground(isSmallScreen, mediaAspectRatioSmallScreen),
		width: '100%',
		maxWidth: '100%',
		zIndex: 5,
		display: 'flex',
		p: { md: '5%', xs: '5% 2%' },
		...resolveContentPositioning(contentPosition),
		[theme.breakpoints.down('md')]: {
			height: 'auto',
		},
		[theme.breakpoints.up('md')]: { minHeight: 'calc(100vh - 200px)' },
		...(isNoContent && { aspectRatio: '16/9' }),
	};
};

export const outerWrapperStyles = (contentWidth?: Maybe<THeroContentWidth>) => ({
	width: contentWidthPercentage(contentWidth),
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: 'auto',
});

const contentWidthPercentage = (width: Maybe<THeroContentWidth> | undefined) => {
	switch (width) {
		case 'Small':
			return '40%';
		case 'Medium':
			return '60%';
		case 'Large':
			return '100%';
		default:
			return '60%';
	}
};

export const getInnerWrapperStyles = (isSmallScreen: boolean, contentAlign?: Maybe<string>) => ({
	width: '100%',
	height: { xs: '100%', md: 'auto' },
	paddingBottom: { xs: '0.5rem', md: '0' },
	textAlign: isSmallScreen ? 'center' : contentAlign || 'center',
	px: isSmallScreen ? '1.5rem' : 0,
});

export const eyebrowStyles = {
	fontSize: { md: '1.125rem', xs: '1rem' },
};

export const getBodyContainerStyles = (
	contentAlign: string,
	darkTextColor: boolean,
	isSmallScreen: boolean
): SxProps => ({
	'& > ul': {
		paddingLeft: '0.3rem',
		color: darkTextColor ? 'text.secondary' : 'text.light',
		textAlign: contentAlign,
		listStylePosition: 'inside',
		listStyleType: { xs: 'none', md: 'square' },
		fontSize: { xs: '0.875rem', md: '1rem' },
	},
	'& > ul li p': {
		m: 0,
		lineHeight: '2rem',
		display: 'inline-block',
		textAlign: contentAlign,
		// HACK: list styles look pretty bad on smaller screens, so we'll just use a HTML entity bullet point instead
		...(isSmallScreen
			? {
					'&:before': {
						content: '"⦁ "',
					},
			  }
			: {}),
	},
	marginBottom: { xs: '1rem', md: '2.75rem' },
});

export const heroHeadingStyles = { paddingLeft: { sm: 0 }, paddingBottom: { xs: '0.5rem', md: '1.25rem' } };

export const getPromoHeadlineSizeVariant = (promoHeadlineSize?: string, isSmallScreen?: boolean) => {
	if (!isSmallScreen) {
		if (promoHeadlineSize === 'Large') {
			return 'heroLarge';
		}

		return 'heroXL';
	}

	return 'heroLarge';
};

export const getHeroHeadingVariant = (headingSize?: string, isSmallScreen?: boolean) => {
	if (!isSmallScreen) {
		if (headingSize === 'Medium') {
			return 'heroMedium';
		}

		return 'heroLarge';
	}

	return 'heroMedium';
};

export const getHeroButtonWrapperStyles = (contentAlign?: Maybe<string>, isSmallScreen?: boolean) => {
	return {
		display: 'flex',
		flexWrap: 'wrap',
		gap: { xs: '0.5rem', md: '1rem' },
		padding: { xs: '0 0.375rem 0.75rem', md: '0 0 1.25rem 0' },
		margin: { xs: '0 auto', md: contentAlign === 'Center' ? 'auto' : 0 },
		width: '100%',
		justifyContent: isSmallScreen || contentAlign === 'Center' ? 'center' : 'unset',
	};
};

export const TACWrapperStyles = { paddingTop: { xs: '1.25rem', md: '0' }, marginBottom: 0 };
