export const isNullOrEmpty = (value: unknown): boolean => {
	if (
		!value ||
		(typeof value === 'string' && value.length === 0) ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' && value !== null && Object.keys(value).length === 0)
	) {
		return true;
	}
	return false;
};
